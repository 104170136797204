import React from "react";
import { Container, Row, Col } from "reactstrap";
import Fade from "react-reveal/Fade";

const Proposal = () => {
  return (
    <section className="section our-proposal" id="about">
      <div className="shapes-container">
        <Fade bottom delay={300}>
          <div className="shape shape-circle pattern-dots" />
        </Fade>

        <Fade bottom>
          <div className="shape shape-circle shape-circle-fill" />
        </Fade>

        <div className="pattern pattern-dots" />
      </div>

      <Container className="bring-to-front">
        <Row className="align-items-center">
          <Col md="6" className="order-md-last my-5">
            <figure
              className="mockup"
              style={{ width: "920px", maxWidth: "100%" }}
            >
              <img
                src={require("assets/img/screens/wmockup/1.png")}
                className="img-responsive"
                alt="..."
              />
            </figure>
          </Col>

          <Col md="6">
            <h2 className="heading-line">The need for a tool like this one</h2>
            <p className="lead">
              This voice analysis tool is an alternative to existing tools for speech and acoustic analysis. InFormant provides a smooth and easy user experience in order to make voice analysis more accessible. The InFormant codebase is open-source, to help other developers create similar voice tools. To advance our mission of improving access to voice tools, you can pay whatever you want ($0 or more) for personal use. We also offer a fixed price, perpetual license to professional use.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Proposal;
