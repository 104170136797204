import React from "react";

// shared page components
import PowerfulFeatures from "./shared/PowerfulFeatures.jsx";
import Pricing from "./shared/Pricing.jsx";
import Subscribe from "./shared/Subscribe.jsx";

// core components
import DefaultNavbar from "components/Navbars/DefaultNavbar.jsx";
import DefaultFooter from "components/Footers/DefaultFooter.jsx";

// alternative 1 page components
import Heading from "./alter1/Heading.jsx";
import FeaturesCard from "./alter1/FeaturesCard.jsx";
import Proposal from "./alter1/Proposal.jsx";

class Index extends React.Component {
  render() {
    return (
      <>
        <DefaultNavbar />
        <main ref="main">
          {/* Alternative 1 specific components */}
          <Heading />
          <FeaturesCard />
          <Proposal />

          {/* Shared Components */}
          <PowerfulFeatures />
          <Pricing proPrice={this.props.proPrice} />
          <Subscribe />
        </main>
        <DefaultFooter />
      </>
    );
  }
}

export default Index;
