import React from "react";
import { Container, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class FeaturesCard extends React.Component {
  createCards = () => {
    let features = [];
    let cards = [
      {
        icon: "microphone-alt",
        title: "real time tracking",
        description:
          "Accurate pitch estimation and resonance tracking performed in real time"
      },
      {
        icon: "chart-bar",
        title: "powerful spectrogram",
        description:
          "With a powerful spectrogram that also displays the tracked pitch and resonances"
      },
      {
        icon: "waveform-path",
        title: "glottal inverse filtering",
        description:
          "An experimental real time glottal inverse filtering of the input signal"
      }
    ];

    cards.forEach((el, i) => {
      features.push(
        <Col md="4" key={i}>
          <div className="icon-box rounded gradient gradient-primary-light text-contrast shadow icon-xl">
            <FontAwesomeIcon icon={['fal', el.icon]} className="m-0 icon pe-3x" />
          </div>
          <h4 className="semi-bold mt-4 text-capitalize">{el.title}</h4>

          <hr className="w-25 bw-2 border-alternate mt-3 mb-4" />
          <p className="regular text-muted">{el.description}</p>
        </Col>
      );
    });

    return features;
  };

  render() {
    return (
      <section className="section mt-6n" id="features">
        <Container className="pt-0">
          <div className="bg-contrast shadow rounded p-5">
            <Row className="gap-y">{this.createCards()}</Row>
          </div>
        </Container>
      </section>
    );
  }
}

export default FeaturesCard;
