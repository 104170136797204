import React from "react";
import { Container } from "reactstrap";

const Subscribe = () => {
  return (
    <section className="section stay-tunned" id="contact">
      <Container className="bring-to-front">
        <div className="shadow rounded p-5 bg-contrast overflow-hidden">
          <div className="section-heading text-center">
            <h2 className="heading-line">Get in contact</h2>
            <p className="lead text-alternate">
              If you have any questions you can contact us using this form
            </p>
          </div>

          <div className="mx-auto position-relative form-wrapper">
            <form
              className="form text-center"
              data-response-message-animation="slide-in-left"
              method="POST"
              action="https://formspree.io/cloyunhee@gmail.com"
            >
              <input type="hidden" name="_subject" value="Message from InFormant website" />
              <div className="form-group input--group">
                <input
                  id="subscribe-email"
                  type="email"
                  name="_replyto"
                  className="form-control form-control-lg text-left"
                  placeholder="Enter your email"
                  required
                />
              </div>
              <div className="form-group input--group">
                <textarea
                  id="subscribe-message"
                  name="message"
                  className="form-control form-control-lg text-left"
                  placeholder="Enter your message"
                  rows="5"
                  style={{resize: 'none'}}
                  required
                />
              </div>

              <button type="submit" className="btn btn-lg btn-alternate">
                Send
              </button>
            </form>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Subscribe;
