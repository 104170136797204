import React from "react";
import { Container, Row, Col } from "reactstrap";
import PowerfulCard from "./PowerfulCard.jsx";

const createCards = use => {
  let features = [];
  let cards = [
    {
      class: "mt-6 mt-6 mx-lg-auto",
      icon: "microphone-alt",
      title: "Formant tracker",
      animation: { delay: 0 },
      description: "Tracking vocal tract resonances"
    },

    {
      class: "mx-lg-auto",
      icon: "waveform-path",
      title: "Glottal inverse filtering",
      animation: { delay: 200 },
      description: "Glottal flow waveform estimation"
    },

    {
      class: "mr-lg-auto",
      icon: "chart-bar",
      title: "Spectrogram",
      animation: { delay: 400 },
      description: "Enhanced scrolling spectrogram"
    },

    {
      class: "mt-6n mr-lg-auto",
      icon: "piano-keyboard",
      title: "Resynthesizer",
      animation: { delay: 600 },
      description: "Tunable vocal synthesizer"
    }
  ];

  cards.forEach((el, i) => {
    if (i % 2 === use) {
      features.push(<PowerfulCard {...el} key={i} />);
    }
  });

  return features;
};

const PowerfulFeatures = () => {
  return (
    <section className="section alter3-features" id="product">
      <div className="shapes-container">
        <div className="shape shape-ring shape-ring-1">
          <div className="animation animation--rotating" />
        </div>
        <div className="shape shape-ring shape-ring-2">
          <div className="animation animation--rotating" />
        </div>
        <div className="shape shape-circle animation--clockwise">
          <div />
        </div>

        <div className="shape background-shape-main" />
      </div>

      <Container>
        <Row>
          <Col lg="5" className="order-lg-last">
            <div className="section-heading">
              <p className="px-2 text-alternate text-uppercase bold">
                Powerful voice analysis features
              </p>
              <h2 className="heading-line">
                A complete feature stack ready for you to use
              </h2>

              <p> 
                These features have been carefully selected and developed with
                the help and feedback of actual voice professionals.{" "}
              </p>
            </div>
          </Col>

          <Col lg="7" className="pr-lg-6">
            <Row>
              <Col lg="6" className="rotated-cards">
                {createCards(0)}
              </Col>
              <Col lg="6" className="rotated-cards">
                {createCards(1)}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default PowerfulFeatures;
