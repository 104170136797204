import React from "react";
import { Container, Row, Col, Nav } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Fade from "react-reveal/Fade";
import { Link as ScrollLink } from 'react-scroll';

const animations = [
  { duration: 1500, delay: 100 },
  { duration: 1000, delay: 100 },
  { duration: 1000, delay: 200 },
  { duration: 1000, delay: 200 },
  { duration: 1000, delay: 100 },
  { duration: 1000, delay: 100 },
  { duration: 1000, delay: 300 },
  { duration: 500, delay: 200 },
  { duration: 500, delay: 100 },
  { duration: 2000, delay: 500 },
  { duration: 500, delay: 200 },
  { duration: 500, delay: 100 },
  { duration: 500, delay: 0 },
  { duration: 500, delay: 0 },
  { duration: 500, delay: 100 },
  { duration: 500, delay: 0 }
];

const Heading = () => {
  return (
    <header className="header alter1-header section text-contrast" id="top">
      <div className="shapes-container">
        {animations.map((el, i) => (
          <Fade bottom duration={el.duration} delay={el.delay} key={i}>
            <div className="shape shape-animated shape-diagonal" key={i} />
          </Fade>
        ))}
      </div>

      <Container className="container">
        <Row>
          <Col md="6">
            <h1 className="text-contrast display-4 display-md-3">
              <img src={require("assets/img/logo-text-dark.png")} className="img-responsive" alt="InFormant" />
            </h1>
            <p className="lead bold">Real Time Voice Analysis Software</p>

            <p className="lead">
              InFormant is an open-source application for voice power users which features pitch tracking, formant tracking and much more. Available for Windows, Linux, macOS, and Android.
            </p>

            <Nav className="my-5" tag="nav">
              <ScrollLink
                href="#pricing" to="pricing"
                className="btn btn-rounded btn-alternate mr-2 mr-md-5"
                smooth={true} offset={-70} duration={500}
              >
                Get now
                <FontAwesomeIcon
                  icon={["fas", "long-arrow-alt-right"]}
                  className="ml-2"
                />
              </ScrollLink>
              <a
                href="https://github.com/clo-yunhee/in-formant"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-rounded btn-contrast scrollto"
              >
                View GitHub
              </a>
            </Nav>
          </Col>

          <Col md="6">
            <div className="iphone front mx-auto">
              <div className="screen shadow-box">
                <img src={require("assets/img/screens/app/9.png")} alt="..." />
              </div>

              <div className="notch" />
            </div>
          </Col>
        </Row>
      </Container>
    </header>
  );
};

export default Heading;
