import React, { useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Fade from "react-reveal/Fade";
import { Link } from 'react-router-dom';

const animations = [
  { duration: 1500, delay: 100 },
  { duration: 1000, delay: 100 },
  { duration: 1000, delay: 200 },
  { duration: 1000, delay: 200 },
  { duration: 1000, delay: 100 },
  { duration: 1000, delay: 100 },
  { duration: 1000, delay: 300 },
  { duration: 500, delay: 200 },
  { duration: 500, delay: 100 },
  { duration: 2000, delay: 500 },
  { duration: 500, delay: 200 },
  { duration: 500, delay: 100 },
  { duration: 500, delay: 0 },
  { duration: 500, delay: 0 },
  { duration: 500, delay: 100 },
  { duration: 500, delay: 0 }
];

const Heading = () => {
  const [price, setPrice] = useState(0);

  return (
    <header className="header alter2-header section text-contrast" id="top">
      <div className="shapes-container">
        {animations.map((el, i) => (
          <Fade bottom duration={el.duration} delay={el.delay} key={i}>
            <div className="shape shape-animated shape-diagonal" key={i} />
          </Fade>
        ))}
      </div>

      <Container className="container">
        <Row>
          <Col md="6">
            <h1 className="text-contrast display-4 display-md-3">
              <span className="bold">Basic</span>
            </h1>
            <p className="lead bold">For personal use</p>

            <p className="lead mb-9">
              Name your price ($0 or more).
            </p>
          </Col>
          
          <Col md="6" className="mt-5">
            <Row>
              <div className="form-group input--group">
                <div className="basic-price-prefix">
                  <FontAwesomeIcon icon={["fas", "dollar-sign"]} />
                </div>
                <input
                  id="basic-price"
                  type="number"
                  name="price"
                  className="form-control form-control-md text-left basic-price"
                  placeholder="Name your price"
                  required
                  onChange={e => setPrice(e.target.value)}
                  defaultValue={0}
                />
              </div>
            </Row>
            <Row>
              <Link
                to={price > 0 ? {pathname: "/basic-buy", state: {price}} : "/download"}
                className="my-2 btn btn-rounded btn-alternate mr-2 mr-md-5"
              >
                {price > 0 ? "Buy" : "Download"}
                <FontAwesomeIcon
                  icon={["fas", "long-arrow-alt-right"]}
                  className="ml-2"
                />
              </Link>
            </Row>
          </Col>
        </Row>
      </Container>
    </header>
  );
};

export default Heading;
