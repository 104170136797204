import React from 'react';

// core components
import DefaultNavbar from "components/Navbars/DefaultNavbar.jsx";
import DefaultFooter from "components/Footers/DefaultFooter.jsx";

import ChoosePrice from "../orders/ChoosePrice.jsx";

class BasicChoosePrice extends React.Component {
  render() {
    return (
      <>
        <DefaultNavbar location={this.props.location} />
        <main ref="main">
          <ChoosePrice />
        </main>
        <DefaultFooter sticky={true} />
      </>
    );
  }
}

export default BasicChoosePrice;
