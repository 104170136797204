import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// routing components
import Index from "views/Index.jsx";
import BasicChoosePrice from "views/orders/basic-choose-price.jsx";
import BasicBuy from "views/orders/basic-buy.jsx";
import ProBuy from "views/orders/pro-buy.jsx";
import Download from "views/orders/download.jsx";

// global template script file
import "./laapp.js";

const proPrice = 199.99;

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/" exact render={props => <Index {...props} proPrice={proPrice} />} />

      <Route path="/basic-choose-price" render={props => <BasicChoosePrice {...props} />} />
      <Route path="/basic-buy" render={props => <BasicBuy {...props} />} />
      <Route path="/pro-buy" render={props => <ProBuy {...props} proPrice={proPrice} />} />
      
      <Route path="/download" render={props => <Download {...props} />} />

      <Redirect to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
