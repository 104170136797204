import React from 'react';

// core components
import DefaultNavbar from "components/Navbars/DefaultNavbar.jsx";
import DefaultFooter from "components/Footers/DefaultFooter.jsx";

import Buy from "../orders/ProBuy.jsx";

class ProBuy extends React.Component {
  render() {
    return (
      <>
        <DefaultNavbar location={this.props.location} />
        <main ref="main">
          <Buy price={this.props.proPrice} />
        </main>
        <DefaultFooter sticky={true} />
      </>
    );
  }
}

export default ProBuy;
