import React from "react";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import classNames from "classnames/bind";

// reactstrap components
import { NavbarBrand, NavItem, Nav, Container } from "reactstrap";

class DefaultNavbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      navbarExpanded: false
    };

    this.navbar = React.createRef();

    this.toggleNavbar = this.toggleNavbar.bind(this);
  }

  toggleNavbar() {
    this.setState(prevState => ({
      navbarExpanded: !prevState.navbarExpanded
    }));
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const navigation = this.navbar.current;
    const navTop = navigation.offsetTop + navigation.offsetHeight;

    if (window.scrollY >= navTop) {
      navigation.classList.add("navbar-sticky");
    } else {
      navigation.classList.remove("navbar-sticky");
    }
  };

  render() {
    const useOnlyDarkLogo = this.props.useOnlyDarkLogo;

    return (
      <nav
        ref={this.navbar}
        className={classNames(
          "navbar navbar-expand-md main-nav navigation fixed-top sidebar-left",
          { "navbar-expanded": this.state.navbarExpanded }
        )}
      >
        <Container>
          <button
            className="navbar-toggler"
            type="button"
            onClick={this.toggleNavbar}
          >
            <span className="icon-bar" />
            <span className="icon-bar" />
            <span className="icon-bar" />
          </button>

          <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
            {useOnlyDarkLogo ? (
              <img
                alt="..."
                src={require("assets/img/logo.png")}
                className="logo logo-sticky"
              />
            ) : (
              <>
                <img
                  src={require("assets/img/logo.png")}
                  alt="Laapp"
                  className="logo logo-sticky d-block d-md-none"
                />
                <img
                  src={require("assets/img/logo-light.png")}
                  alt="Laapp"
                  className="logo d-none d-md-block"
                />
              </>
            )}
          </NavbarBrand>

          <div className="collapse navbar-collapse">
            <div className="sidebar-brand">
              <Link to="/">
                <img
                  src={require("assets/img/logo.png")}
                  alt="Laapp Template"
                  className="logo"
                />
              </Link>
            </div>

            <Nav className="nav navbar-nav ml-auto" navbar>
              { (!this.props.location || this.props.location.pathname === '/' || this.props.location.pathname.startsWith("/#")) ? (
                  <>
                      <NavItem>
                        <ScrollLink href="#top" to="top" className="nav-link" smooth={true} spy={true} offset={-70} duration={500}>
                          Home
                        </ScrollLink>
                      </NavItem>
                      <NavItem>
                        <ScrollLink href="#about" to="about" className="nav-link" smooth={true} spy={true} offset={-70} duration={500}>
                          About
                        </ScrollLink>
                      </NavItem>
                      <NavItem>
                        <ScrollLink href="#product" to="product" className="nav-link" smooth={true} spy={true} offset={-70} duration={500}>
                          Product
                        </ScrollLink>
                      </NavItem>
                      <NavItem>
                        <ScrollLink href="#pricing" to="pricing" className="nav-link" smooth={true} spy={true} offset={-70} duration={500}>
                          Pricing
                        </ScrollLink>
                      </NavItem>
                  </>
              ) : (<NavItem> <a href="/" className="nav-link">Home</a> </NavItem>)}
            </Nav>
          </div>
        </Container>
      </nav>
    );
  }
}

export default DefaultNavbar;
