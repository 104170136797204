import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

const DefaultFooter = ({sticky}) => {

  const styles = sticky ? {'position': 'absolute', 'bottom': 0, 'width': '100%'} : {};
  
  const className = sticky ? "" : " top-left";

  return (
    <footer className={"site-footer section bg-dark text-contrast edge" + className} style={styles}>
      <Container className="py-3">
        <Row className="row gap-y text-center text-md-left">
          <Col md="4" className="mr-auto">
            <img
              src={require("assets/img/logo-light.png")}
              alt=""
              className="logo"
            />

            <p>
              InFormant, a simple but powerful voice analysis and synthesis software.
            </p>
          </Col>

        </Row>

        <hr className="mt-5 op-5" />

        <Row className="small">
          <Col md="4">
            <p className="mt-2 mb-0 text-center text-md-left text-nowrap">
              © {new Date().getFullYear()}, {" "}
              <a
                href="https://cloyunhee.dev"
                target="_blank"
                rel="noopener noreferrer"
              >
                Clo Yun-Hee Dufour
              </a>
              . All Rights Reserved
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default DefaultFooter;
