import React from 'react';

// core components
import DefaultNavbar from "components/Navbars/DefaultNavbar.jsx";
import DefaultFooter from "components/Footers/DefaultFooter.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Download extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loaded: false,
            name: 'InFormant',
            release: '#',
            issues: '#',
            linux: {browser_download_url: '#', size: 0},
            win64: {browser_download_url: '#', size: 0},
            macos: {browser_download_url: '#', size: 0},
            linux_df: {browser_download_url: '#', size: 0},
            win64_df: {browser_download_url: '#', size: 0},
            macos_df: {browser_download_url: '#', size: 0},
        };
    }

  render() {
      fetch("https://api.github.com/repos/clo-yunhee/in-formant/releases/latest")
        .then(res => res.json())
        .then(result => {
            const prefix = "InFormant-" + result.tag_name;
            const linux = result.assets.find((v) => v.name === prefix + "-Linux-x86_64.AppImage");
            const win64 = result.assets.find((v) => v.name === prefix + "-win64-x64.zip");
            const macos = result.assets.find((v) => v.name === prefix + "-macOS-x86_64.dmg");

            const prefix_df = "InFormant-DF-" + result.tag_name;
            const linux_df = result.assets.find((v) => v.name === prefix_df + "-Linux-x86_64.AppImage");
            const win64_df = result.assets.find((v) => v.name === prefix_df + "-win64-x64.zip");
            const macos_df = result.assets.find((v) => v.name === prefix_df + "-macOS-x86_64.dmg");

            this.setState({
                loaded : true,
                name: result.name,
                release: result.html_url,
                issues: 'https://github.com/clo-yunhee/in-formant/issues',
                linux: linux,
                win64: win64,
                macos: macos,
                linux_df: linux_df,
                win64_df: win64_df,
                macos_df: macos_df,
            });
        });

    const {name, release, issues, linux, win64, macos, linux_df, win64_df, macos_df} = this.state;

    return (
      <>
        <DefaultNavbar location={this.props.location} />
        <main ref="main">
          <header className="header alter2-header section text-contrast">
            <h2 className="d-flex justify-content-center align-items-center text-center">{name}</h2>

            <div className="d-flex justify-content-center align-items-center mb-4">
              <a className="text-center text-contrast" href={release}><em>(link to corresponding GitHub release)</em></a>
            </div>

            <div className="d-flex justify-content-center align-items-center mb-4">
              <div className="mx-4 p-2">
                <a href={linux.browser_download_url} className="icon-box rounded gradient gradient-primary-dark text-contrast shadow icon-xl mb-2">
                  <FontAwesomeIcon icon={['fab', 'linux']} className="m-0 icon pe-3x" />       
                  <span className="win-arch-note">64</span>
                </a>
                <div className="bold text-right text-muted">{Math.round(linux.size / 1024 / 1024 * 10) / 10} <small>MB</small></div>
              </div>

              <div className="p-2 mx-4">
                <a href={win64.browser_download_url} className="icon-box rounded gradient gradient-primary-dark text-contrast shadow icon-xl mb-2">
                  <FontAwesomeIcon icon={['fab', 'windows']} className="m-0 icon pe-3x" />
                  <span className="win-arch-note">64</span>
                </a>
                <div className="bold text-right text-muted">{Math.round(win64.size / 1024 / 1024 * 10) / 10} <small>MB</small></div>
              </div>
              
              <div className="p-2 mx-4">
                <a href={macos.browser_download_url} className="icon-box rounded gradient gradient-primary-dark text-contrast shadow icon-xl mb-2">
                  <FontAwesomeIcon icon={['fab', 'apple']} className="m-0 icon pe-3x" />
                </a>
                <div className="bold text-right text-muted">{Math.round(macos.size / 1024 / 1024 * 10) / 10} <small>MB</small></div>
              </div>
            </div>

            <div className="d-flex justify-content-center align-items-center">
              <h2 className="text-center mt-2 mb-4"><small>with DeepFormants</small></h2>
            </div>

            <div className="d-flex justify-content-center align-items-center mb-4">
              <div className="mx-4 p-2">
                <a href={linux_df.browser_download_url} className="icon-box rounded gradient gradient-primary-dark text-contrast shadow icon-xl mb-2">
                  <FontAwesomeIcon icon={['fab', 'linux']} className="m-0 icon pe-3x" />       
                  <span className="win-arch-note">64</span>
                </a>
                <div className="bold text-right text-muted">{Math.round(linux_df.size / 1024 / 1024 * 10) / 10} <small>MB</small></div>
              </div>

              <div className="p-2 mx-4">
                <a href={win64_df.browser_download_url} className="icon-box rounded gradient gradient-primary-dark text-contrast shadow icon-xl mb-2">
                  <FontAwesomeIcon icon={['fab', 'windows']} className="m-0 icon pe-3x" />
                  <span className="win-arch-note">64</span>
                </a>
                <div className="bold text-right text-muted">{Math.round(win64_df.size / 1024 / 1024 * 10) / 10} <small>MB</small></div>
              </div>
              
              <div className="p-2 mx-4">
                <a href={macos_df.browser_download_url} className="icon-box rounded gradient gradient-primary-dark text-contrast shadow icon-xl mb-2">
                  <FontAwesomeIcon icon={['fab', 'apple']} className="m-0 icon pe-3x" />
                </a>
                <div className="bold text-right text-muted">{Math.round(macos_df.size / 1024 / 1024 * 10) / 10} <small>MB</small></div>
              </div>
            </div>

            <div className="d-flex justify-content-center align-items-center mb-4">
              Please report any issues on the&nbsp;<a href={issues}>GitHub issue tracker</a>.
            </div>
          </header>
        </main>
        <DefaultFooter sticky={true} />
      </>
    );
  }
}

export default Download;
