import React from 'react';

// core components
import DefaultNavbar from "components/Navbars/DefaultNavbar.jsx";
import DefaultFooter from "components/Footers/DefaultFooter.jsx";

import { Redirect } from 'react-router-dom';

import Buy from "../orders/BasicBuy.jsx";

class BasicBuy extends React.Component {
  render() {
    try {
      // eslint-disable-next-line no-unused-vars
      const test = this.props.location.state.price;
    }
    catch (e) {
      return <Redirect to="/" />
    }

    return (
      <>
        <DefaultNavbar location={this.props.location} />
        <main ref="main">
          <Buy price={this.props.location.state.price} />
        </main>
        <DefaultFooter sticky={true} />
      </>
    );
  }
}

export default BasicBuy;
