import React, { useState } from "react";
import { Container, Row, Col, Spinner } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Fade from "react-reveal/Fade";
import SmartPaymentButtons, { PayPalSDKWrapper } from 'react-smart-payment-buttons';
import { Redirect } from 'react-router-dom';

const animations = [
  { duration: 1500, delay: 100 },
  { duration: 1000, delay: 100 },
  { duration: 1000, delay: 200 },
  { duration: 1000, delay: 200 },
  { duration: 1000, delay: 100 },
  { duration: 1000, delay: 100 },
  { duration: 1000, delay: 300 },
  { duration: 500, delay: 200 },
  { duration: 500, delay: 100 },
  { duration: 2000, delay: 500 },
  { duration: 500, delay: 200 },
  { duration: 500, delay: 100 },
  { duration: 500, delay: 0 },
  { duration: 500, delay: 0 },
  { duration: 500, delay: 100 },
  { duration: 500, delay: 0 }
];

const Heading = ({price}) => {
    const [completed, setCompleted] = useState(false);
    const [error, setError] = useState();

    const createOrder = function (data, actions) {
        return actions.order.create({
            purchase_units: [{
                amount: {
                    value: price
                }
            }]
        });
    };

    const onApprove = function (data, actions) {
        return actions.order.capture().then(function (details) {
            setCompleted(true);
        });
    };

    const onError = function (err) {
        setError(err);
    };

    if (completed) {
        return <Redirect to="/download" />
    }

  return (
    <header className="header alter2-header section text-contrast" id="top">
      <div className="shapes-container">
        {animations.map((el, i) => (
          <Fade bottom duration={el.duration} delay={el.delay} key={i}>
            <div className="shape shape-animated shape-diagonal" key={i} />
          </Fade>
        ))}
      </div>

      <Container className="container">
        <Row>
          <Col md="6">
            <h1 className="text-contrast display-4 display-md-3">
              <span className="bold">Pro</span>
            </h1>
            <p className="lead bold">For professional use</p>

            <p className="lead mb-9">
              <FontAwesomeIcon icon={['fas', 'dollar-sign']} className="buy-price-dollar pe-3x" />
              <span className="buy-price">{" "}{price}</span>
            </p>
          </Col>
          
          <Col md="6" className="mt-5">
            <Row>
              <PayPalSDKWrapper
                clientId="Aep1yyJuJK9I8-o4k34Y5vqX5mNogz3lSUbKiy_TyTuCPdrr4nuvc3Tv6INTzbu65mawKMFGJ9_S9PWB"
                loading={<Spinner />}
                disableFunding={['card']} 
              >
                <SmartPaymentButtons
                  createOrder={createOrder}
                  onApprove={onApprove}
                  onError={onError}
                  style={{label: 'pay', height: 40}}
                  containerClassName="buy-button"
                />
              </PayPalSDKWrapper>
            </Row>
            {error ?
                <Row>
                  <div className="buy-error">{error}</div>
                </Row>
                : undefined}
          </Col>
        </Row>
      </Container>
    </header>
  );
};

export default Heading;
