import React, { useState } from "react";
import classNames from "classnames/bind";
import { Col, ListGroup, ListGroupItem, Modal, ModalHeader, ModalBody } from "reactstrap";
import MoreInfoButton from "components/MoreInfoButton";

const PricingPlan = props => {
  const plan = props

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);


  return (
    <Col
      lg="4"
      className={classNames(`pricing-plan plan-${plan.name} ${plan.class}`, {
        "best-value": plan.best
      })}
    >
      <div className={classNames("card border-0 shadow p-5", { "py-md-6": plan.best })}>
        <div className="text-center">
          <h4
            className={`bold text-capitalize text-${
              plan.best ? "primary" : "alternate"
            }`}
          >
            {plan.name}
          </h4>
          <p>{plan.desc}</p>

          <div className="pricing-details">
            <span className="pricing-value">
              <span className="price">
                {plan.price}
              </span>
            </span>
          </div>

          <MoreInfoButton
            className={`btn btn-${plan.best ? "" : "outline-"}alternate mb-3`}
            text={plan.best ? "Download" : "Buy"}
            to={plan.url}
          />
        </div>

        <hr />

        <div className="d-none d-md-flex">
          <ListGroup flush className="list-group-no-border">
            {plan.details.map((line, i) => <ListGroupItem key={i}>{line}</ListGroupItem>)}
          </ListGroup>
        </div>

        <div className="d-md-none">
          <button
            type="button"
            className="more-link btn btn-link d-flex align-items-center mx-auto"
            onClick={toggle}
          >
            View plan details
          </button>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>
              <h5 className="modal-title bold text-capitalize">
                {plan.name} plan
              </h5>
            </ModalHeader>
            <ModalBody className="plan-features">
              <ListGroup flush className="text-center">
                {plan.details.map((line, i) =>
                    i > 0 ? <ListGroupItem key={i}>{line}</ListGroupItem>
                          : <ListGroupItem key={i} className="border-top-0">{line}</ListGroupItem>)}
              </ListGroup>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </Col>
  );
};

export default PricingPlan;
