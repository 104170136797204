import React from "react";
import { Container, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PricingPlan from "./PricingPlan.jsx";
import { Link } from 'react-router-dom';

const Pricing = props => {
    const plans = [
      {
        name: '',
        desc: '',
        class: 'invisible order-md-first h-0',
        price: 1,
        details: [],
        url: ''
      },
      {
        name: "basic",
        desc: "For personal use",
        price: "0*",
        details: [
            'Access to all the features',
            'Not suitable for professional use',
            '* Pay what you think is fair',
        ],
        url: '/basic-choose-price',
        best: true
      },
      {
        name: "pro",
        desc: "For professional use",
        price: props.proPrice,
        details: [
            'Access to all the features',
            'For professional use',
        ],
        url: '/pro-buy'
      }
    ];

  return (
    <section className="section pricing-plans" id="pricing">
      <div className="shapes-container overflow-clear">
        <div className="shape shape-1" />
        <div className="shape shape-2" />
        <div className="shape shape-3" />
        <div className="shape shape-4" />

        <div className="icon-holder">
          <i className="icon pe pe-7s-cash pe-3x" />

          <FontAwesomeIcon
            icon={["fas", "dollar-sign"]}
            className="icon fa-3x"
          />

          <i className="icon pe pe-7s-piggy pe-3x" />

          <i className="icon pe pe-7s-cart pe-3x" />

          <FontAwesomeIcon
            icon={["far", "credit-card"]}
            className="icon fa-3x"
          />

          <FontAwesomeIcon
            icon={["far", "money-bill-alt"]}
            className="icon fa-3x"
          />
        </div>
      </div>

      <Container>
        <div className="section-heading text-center">
          <span className="rounded-pill shadow-box bold py-2 px-4">
            <FontAwesomeIcon
              icon={["far", "lightbulb"]}
              className="text-primary mr-2"
            />
            Made for <span className="text-primary">your use case</span>
          </span>
          <h2 className="mt-3 heading-line">Pricing</h2>
          <p className="lead text-muted">
            Affordable personal and professional pricing.{" "}
            <span className="highlight">You choose your price.</span>
          </p>
        </div>

        <Row className="no-gutters align-items-center pricing-plans-options">
          {plans.map((plan, i) => (
            <PricingPlan {...plan} key={i} />
          ))}
        </Row>
      </Container>

      <Container className="pt-0 border-bottom">
        <Row className="align-items-center">
          <Col md="8">
            <div className="text-center text-md-left">
              <h4 className="bold accent">Not sure if this software is for you?</h4>
              <p className="mt-0">
                You can try it for free, so long as you stay within trial use case;
                you can decide later if this software suits your needs.{" "}
                <span className="italic dotted">
                  The trial has no feature restriction or time limit.
                </span>
              </p>
            </div>
          </Col>

          <Col md="4" className="text-center text-md-right">
            <Link
              className="btn btn-primary btn-lg text-contrast p-4 ml-md-auto"
              to="/download"
            >
              Try it now!
            </Link>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Pricing;
